@import "./../../styles/variables";

.sticky-bottom {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0.5em;
  z-index: 999; /* Modal (including faded background) has z-index 1042. Stay below that. */
}

.sticky-shadow {
  background-color: rgba(255, 255, 255, 70%);
  box-shadow         : 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2pt;
}
