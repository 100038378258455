@import '../../styles/variables';

.food-course {
  -webkit-hyphens : auto;
  -moz-hyphens    : auto;
  -ms-hyphens     : auto;
  hyphens         : auto;
  border-bottom   : 1px solid $neutral50;
  padding         : 10px 2px;
}

.food-course:first-child {
  padding-top : 0;
}

.food-course:last-child {
  border         : none;
  padding-bottom : 0;
}
