@import './../../styles/variables';

$arrow-size   : 40px;
$border-width : 1px;

.card-arrow {
  width         : 0;
  height        : 0;
  border-left   : $arrow-size solid transparent;
  border-right  : $arrow-size solid transparent;
  border-bottom : $arrow-size solid $primary-color;
  position      : relative;
  top           : $border-width;
  z-index       : 4;
  margin        : auto;

  .arrow-inner {
    width         : 0;
    height        : 0;
    border-left   : $arrow-size solid transparent;
    border-right  : $arrow-size solid transparent;
    border-bottom : $arrow-size solid #fff;
    position      : relative;
    left          : -($arrow-size);
    top           : $border-width * 2;
  }
}
