@import '../../styles/variables';

.toggle {
  label {
    position : relative;
    display  : inline-block;
    width    : 40px;
    height   : 24px;
  }

  input {
    opacity : 0;
    width   : 0;
    height  : 0;
  }

  span {
    position      : absolute;
    cursor        : pointer;
    top           : 0;
    left          : 0;
    right         : 0;
    bottom        : 0;
    background    : $neutral60;
    transition    : 0.3s;
    border-radius : 30px;
  }

  span:before {
    position         : absolute;
    content          : '';
    height           : 18px;
    width            : 18px;
    left             : 3px;
    bottom           : 2.6px;
    background-color : #fff;
    border-radius    : 50%;
    transition       : 0.3s;
  }

  input:checked + span {
    background-color : $primary-color;
  }

  input:disabled {

  }

  input:checked + span:before {
    transform : translateX(18px);
  }

  strong {
    position    : absolute;
    left        : 100%;
    width       : max-content;
    line-height : 30px;
    margin-left : 10px;
    cursor      : pointer;
  }
}
