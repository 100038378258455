@import '../../styles/variables';

#header {
  padding          : 0.5rem 0;
  background-color : white;
  height           : $header-height-default;
  position         : fixed;
  top              : 0;
  left             : 0;
  width            : 100vw;
  z-index          : 1030;
  @media (min-width : $viewport-md-min) {
    height : $header-height-md;
  }
  @media (min-width : $viewport-xl-min) {
    height : $header-height-xl;
  }


  box-shadow       : 0 7px 50px rgba(222, 222, 222, 0.4);

  .caterer-logo {
    img {
      max-height : 36px;
      max-width  : 105px;
      @media (min-width : $viewport-xl-min) {
        max-height : 48px;
        max-width  : 140px;
      }
    }
  }

  .navbar-brand {
    margin : auto 0 auto 40px;
  }

  .navbar-toggler {
    margin  : 0 40px 0 0;
    z-index : 1001;
    border  : none;
  }
}

#header-navigation {

  @media (max-width : $viewport-md-max) {
    @media (min-width : $viewport-md-min) {
      top : 64px;
    }
    top        : 54px;
    position   : absolute;
    width      : 100%;
    overflow-y : scroll !important;
    max-height : calc(100vh - 54px) !important;
    .collapsed {
      overflow-y : initial;
    }
  }
}

#navigation {

  z-index : 2000;

  @media (max-width : $viewport-md-max) {
    background     : white;
    padding-bottom : 12px;

    a {
      margin : 0 0 24px 0;
    }
  }

  @media (min-width : $viewport-lg-min) {
    margin : 0 40px 0 0;
  }

  .nav-item {
    &.dropdown {
      margin-top : 6px;
    }
  }

  .dropdown-menu {
    border     : none;
    box-shadow : 0 4px 26px rgba(204, 204, 204, 0.25);
    padding    : 4px 15px;
  }

  .dropdown-navigation-link {
    text-decoration  : none;
    background-color : white;

    &.active {
      text-decoration : none;
      color           : $primary-color
    }

    &:hover {
      text-decoration : none;
    }

    color            : $text-color;
    font-weight      : 600;
    font-size        : 16px;
    line-height      : 24px;
    display          : block;
    margin           : 3px 0 28px 0;
    padding          : 4px 0;

    @media (min-width : $viewport-lg-min) {
      margin : 9px 30px 9px 10px;
    }
  }
}

#icon-nav-dropdown {
  color          : $text-color;
  margin-right   : 4px;
  font-weight    : 600;
  letter-spacing : $big-letter-spacing;

  &.active {
    color               : $primary-color;
    border-bottom       : $primary-color;
    border-bottom-style : solid;
    border-width        : 3px;
    padding-bottom      : 6px;
  }

  .nav-dropdown-icon {
    margin : 0 8px 0 0;
  }
}

#menu-overlay {
  &.active {
    position   : fixed;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    background : rgba($neutral50, 0.75);
    z-index    : 1001;
  }
}
