.order-type-advantages li {
  > strong:after {
    display: inline;
    content: ' - ';
    font-weight: normal;
  }

  .info-tooltip {
    font-size: 1rem;
  }
}
