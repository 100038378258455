@import '../../styles/variables';

.tag {
  margin-right     : 8px;
  padding          : 8px 13px;
  background-color : $neutral-color;
  font-weight      : 700;
  font-size        : 16px;
  line-height      : 24px;
  letter-spacing   : 0.02em;
  border-radius    : 3px;

  &.primary {
    background-color : $primary-color;
    color            : white;
  }

  &.dark {
    background-color : $dark-color;
    color            : white;
  }

  &.medium {
    padding     : 6px 13px;
    font-style  : normal;
    font-weight : 700;
    font-size   : 14px;
    line-height : 21px;
  }

  &.small {
    padding     : 1px 8px 0;
    font-style  : normal;
    font-weight : 700;
    font-size   : 14px;
    line-height : 21px;

  }

  &.tiny {
    padding     : 3px 5px;
    font-style  : normal;
    font-weight : 700;
    font-size   : 12px;
    line-height : 18px;
  }

  &.danger {
    background-color : #d13200;
    color            : white;
  }

  &.success {
    background-color : $green;
    color            : white;
  }

  &.warning {
    background-color : #425669;
    color            : white;
  }

  &.info {
    color : $text-color;
  }
}
