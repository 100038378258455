@import '../../styles/variables';

footer {
  position   : absolute;
  bottom     : 0;
  width      : 100%;
  text-align : left;
  font-size  : 0.9rem;
  color      : $primary-color;
  height     : 62px;
  border-top : 2px solid #ECEBE8;
  background : white;

  .footer-container {
    @media (max-width : $viewport-sm-max) {
      display : grid;
      padding : 24px 16px;
    }
    position    : relative;
    display     : flex;
    padding     : 16px 40px;
    line-height : 28px;

    a {
      color  : $text-color;
      margin : 0 0.5rem;
      @media (max-width : $viewport-sm-max) {
        padding-bottom : 16px;
      }
    }

    a:hover {
      text-decoration : none;
      color           : $primary-color;
    }

    .right-content {
      @media (max-width : $viewport-sm-max) {
        position     : relative;
        right        : 0;
        padding-left : 8px;
      }
      display  : flex;
      position : absolute;
      right    : 40px;
    }

    .logo-container {
      margin-top: -4px;

      img {
        max-width: 120px;
        max-height: 40px;
      }

      @media (max-width : $viewport-sm-max) {
        position : absolute;
        right    : 0;
        max-width: 100px;
        max-height: 35px;
      }
    }
  }
}
