@import '../../styles/variables';
@import '../../styles/basics';

.pagination {
  border-top : none !important;
}

.page-link {
  color : $primary-color-dark;

  &:hover {
    color : $primary-color;
  }
}

.page-item.active .page-link {
  background-color : $primary-color;
  border-color     : $primary-color;
}
