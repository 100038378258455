.card.confirm-card {
  .card-header {
    text-align: center;
  }

  .card-body {
    text-align: center;
    padding-top: 0.5rem;
  }
}
