@import '../../styles/variables';

.price {
  &.thin {
    font-weight : normal;
  }

  &.bold {
    font-weight : 600;
  }

  &.debit {
    color : $red
  }

  &.credit {
    color : $success
  }

  &.price-big {
    font-size: 1.5rem;
  }
}

.secondary-price-small {
  position : relative;
  top      : -10px;

  &.debit {
    color : $red
  }

  &.credit {
    color : $success
  }
}

.secondary-price-big {
  position : relative;
  top      : -15px;

  &.debit {
    color : $red
  }

  &.credit {
    color : $success
  }
}
