// --------------------------do not change the order of imports!!! it will also change the style
@import '~bootstrap/scss/bootstrap';
@import '~react-datepicker/dist/react-datepicker.css';
// --------------------------do not change the order of imports!!! it will also change the style

// START: add internal Styles here
@import 'variables';
@import 'typography';
@import 'basics';
@import 'icon';
@import 'bootstrap-theme';
@import 'zack';
@import 'animations';
@import 'button';
// END: add internal Styles here

// --------------------------do not change the order of imports!!! it will also change the style
@import '~react-image-crop/dist/ReactCrop.css';
@import '~@fontsource/catamaran/100';
@import '~@fontsource/catamaran/200';
@import '~@fontsource/catamaran/300';
@import '~@fontsource/catamaran/400';
@import '~@fontsource/catamaran/500';
@import '~@fontsource/catamaran/600';
@import '~@fontsource/catamaran/700';
@import '~@fontsource/catamaran/800';
@import '~@fontsource/catamaran/900';
// --------------------------do not change the order of imports!!! it will also change the style
