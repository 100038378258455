@import '../../styles/variables';

#login {
  width       : 100%;

  padding-top : $header-height-default;

  @media (min-width : $viewport-md-min) {
    padding-top : $header-height-md;
  }
  @media (min-width : $viewport-xl-min) {
    padding-top : $header-height-xl;
  }

  .login-welcome-image-container {
    width  : 50%;
    height : calc(100vh - calc($header-height-default + $footer-height));

    @media (min-width : $viewport-md-min) {
      height : calc(100vh - calc($header-height-md + $footer-height));
    }
    @media (min-width : $viewport-xl-min) {
      height : calc(100vh - calc($header-height-xl + $footer-height));
    }

    @media (max-width : $viewport-md-max) {
      width      : 100%;
      max-height : 30vh;
    }

    .login-welcome-image {
      width      : 100%;
      height     : 100%;
      object-fit : cover;
    }
  }

  .login-form {
    width         : 50%;
    padding-left  : min(max(160px, 33vw), 10vw);
    padding-right : min(max(160px, 33vw), 10vw);
    padding-top   : 168px;

    @media (max-width : $viewport-md-max) {
      width   : 100%;
      padding : 30px 24px 60px;

      .login-button {
        margin-top : 30px;
        width      : 100%;
      }
    }

    .login-header {
      margin-bottom : 16px;
    }

    .login-registration {
      margin-bottom : 46px;
    }
  }
}
