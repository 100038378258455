.icon-tooltip {
  position        : relative;
  display         : inline-flex;
  align-items     : center;
  justify-content : center;
  text-align      : center;
  text-decoration : none;
  white-space     : normal;
  vertical-align  : middle;
  line-height     : 12px;
}