.fade-in {
  -webkit-animation : fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation         : fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-11-12 16:56:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: $cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity : 0;
  }
  100% {
    opacity : 1;
  }
}

@keyframes fade-in {
  0% {
    opacity : 0;
  }
  100% {
    opacity : 1;
  }
}
