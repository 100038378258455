h1 {
  font-family: $header-font;

  small {
    font-family: $subheader-font;
    margin-left: 2rem;
    font-size: 75%;
    display: block;
  }
}

h2 {
  font-size: 1.1rem;
  font-family: $subheader-font;
}

@media (min-width: $viewport-sm-min) {
  h2 {
    font-size: 1.5rem;
  }
}

@media (min-width: $viewport-lg-min) {
  h2 {
    font-size: 2rem;
  }
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 1.4rem 0 1rem 0;
}
