@import '../../styles/variables';

.overlay {
  background : rgba(255, 255, 255, 0.75);
  position   : fixed;
  top        : 0;
  left       : 0;
  width      : 100%;
  height     : 100%;
  z-index    : 9000;
}

.loader-container {
  position          : absolute;
  top               : 50%;
  left              : 50%;
  -webkit-transform : translate(-50%, -50%);
  transform         : translate(-50%, -50%);

  .loader-roller {
    margin: auto;
    width    : 80px;
    height   : 80px;
  }

  .loader-roller div {
    animation        : loader-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin : 40px 40px;
  }

  .loader-roller div:after {
    content       : ' ';
    display       : block;
    position      : absolute;
    width         : 7px;
    height        : 7px;
    border-radius : 50%;
    background    : $primary-color;
    margin        : -4px 0 0 -4px;
  }

  .loader-roller div:nth-child(1) {
    animation-delay : -0.036s;
  }

  .loader-roller div:nth-child(1):after {
    top  : 63px;
    left : 63px;
  }

  .loader-roller div:nth-child(2) {
    animation-delay : -0.072s;
  }

  .loader-roller div:nth-child(2):after {
    top  : 68px;
    left : 56px;
  }

  .loader-roller div:nth-child(3) {
    animation-delay : -0.108s;
  }

  .loader-roller div:nth-child(3):after {
    top  : 71px;
    left : 48px;
  }

  .loader-roller div:nth-child(4) {
    animation-delay : -0.144s;
  }

  .loader-roller div:nth-child(4):after {
    top  : 72px;
    left : 40px;
  }

  .loader-roller div:nth-child(5) {
    animation-delay : -0.18s;
  }

  .loader-roller div:nth-child(5):after {
    top  : 71px;
    left : 32px;
  }

  .loader-roller div:nth-child(6) {
    animation-delay : -0.216s;
  }

  .loader-roller div:nth-child(6):after {
    top  : 68px;
    left : 24px;
  }

  .loader-roller div:nth-child(7) {
    animation-delay : -0.252s;
  }

  .loader-roller div:nth-child(7):after {
    top  : 63px;
    left : 17px;
  }

  .loader-roller div:nth-child(8) {
    animation-delay : -0.288s;
  }

  .loader-roller div:nth-child(8):after {
    top  : 56px;
    left : 12px;
  }

  @keyframes loader-roller {
    0% {
      transform : rotate(0deg);
    }
    100% {
      transform : rotate(360deg);
    }
  }
}

