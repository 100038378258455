.online-catering {
  .form-buttons {
    display: flex !important;
  }

  .form-button-alignment {
      margin-right: 0 !important;
      margin-left: auto !important;
  }

  .form-button-margin {
    margin: 0.25em;
    padding: 0.5em;
  }
}
