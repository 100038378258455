.edit-modal-background {
  position         : fixed;
  top              : 0;
  left             : 0;
  width            : 100%;
  height           : 100%;
  background-color : rgba(0, 0, 0, 0.5);
  z-index          : 1042;

  display          : -ms-flexbox !important;
  display          : flex !important;
  -ms-flex-align   : center !important;
  align-items      : center !important;
  -ms-flex-pack    : center !important;
  justify-content  : center !important;
}

.edit-modal {
  background-color : #fff;
  padding          : 1rem;
}

.edit-modal-scrollable {
  overflow-y       : auto;
  max-height       : 80%;
  background-color : #fff;
  padding          : 1rem;

  .card {
    box-shadow : none;
    border     : none;
  }
}

.menu-group-edit-modal {
  overflow-y : visible;
}
