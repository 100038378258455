@import '../../styles/variables';

.stripe-payment-with-fee {
  width         : 312px;
  height        : 43px;
  background    : $neutral-color-20;
  border-radius : 2px;
  flex          : none;
  order         : 1;
  align-self    : stretch;
  flex-grow     : 1;
  margin-left   : 0 !important;
  padding       : 10px;
}
