@import '../../styles/variables.scss';

#subscription {
  .text-primary {
    color : $primary-color !important;
  }

  .steps {
    padding-left : 60px;

    li {
      display     : block;
      padding-top : 5px;
      margin      : 0 0 2rem;
      color       : $text-color;
      position    : relative;

      &:before {
        position : absolute;
        top      : -0.3rem;
        left     : -60px;
      }
    }
  }
}
