@import '../../styles/variables';

.react-datepicker-popper {
  z-index : 9999 !important;
}

.react-datepicker {
  font-family : $basic-font;
}

.react-datepicker--with-time {
  min-width : 328px;
}

.react-datepicker__close-icon::after {
  background-color : transparent;
  color            : $input-border-color;
  height           : 20px;
  width            : 20px;
  font-size        : 20px;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background : $primary-color;

  &:hover {
    background : $primary-color-dark;
  }
}

.react-datepicker__header {
  background-color : #f3f2ef
}
