@import '../../styles/variables';

.CookieConsent {
  @media (min-width : $viewport-md-min) {
    padding : 2rem 5rem;
  }
  @media (max-width : $viewport-md-max) {
    padding : 6rem 1rem 1rem 1rem;
  }
  border-top  : 1px solid #ECEBE8;
  box-shadow  : 0px -4px 26px rgba(204, 204, 204, 0.25);
  box-sizing  : border-box;
  align-items : end !important;
  background  : #fff !important;
  z-index: 9999 !important;

  .cookie-image {
    width    : 168px;
    height   : 156px;
    position : absolute;
    right    : 62px;
    top      : -53px
  }

  .consent-title {
    color       : $text-color;
    font-weight : 700;
    font-size   : 24px;
    line-height : 36px;
  }

  .consent-text {
    color : $text-color;
    @media (min-width : $viewport-md-min) {
      width : 80%
    }
  }

  #rcc-decline-button {
    color         : $primary-color !important;
    padding       : 9px 12px !important;
    border        : 1px solid $primary-color !important;
    border-radius : 3px !important;
    margin        : 7px !important;
    background    : #fff !important;
  }

  #rcc-confirm-button {
    background-color : $primary-color !important;
    color            : #fff !important;
    padding          : 9px 12px !important;
    margin           : 7px !important;
    border-radius    : 3px !important;
  }
}
