// Make the payment control look like our input fields:
.StripeElement {
  border-radius: 0;
  padding: 10px 9px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid #DBDBCD;
}

.StripeElement--focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
