@import '../../styles/variables';

.department-selection {
  .btn:first-child {
    border-bottom-left-radius : 3px;
    border-top-left-radius    : 3px;
    border-right              : none;
  }

  .btn:last-child {
    border-bottom-right-radius : 3px;
    border-top-right-radius    : 3px;
    border-left                : none;
  }

  .btn {
    justify-content  : center;
    border-radius    : 0px;
    background-color : #fff;
    border-color     : $primary-color;
    color            : $primary-color;

    &:hover, &:focus, &:active, &.selected {
      background-color : $primary-color;
      color            : #fff;
    }
  }
}
