
.photo-viewer-container {
  max-width: 80%;
  min-height: 80% !important;
}

.photo {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}

.modal-dialog {
  max-width: 80%;
  max-height: 80%;
}

.pg-viewer-wrapper {
  height: 70vh
}

.pdf-canvas {
  margin: auto
}

canvas {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}
