@import "../../styles/variables";

.text-output {
  dt {
    font-size: 70%;
    color: $gray-text-color;
    font-weight: normal;
  }

  margin-bottom: 10px;
}
