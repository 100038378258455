@import '../../styles/variables';

.online-catering {
  .parent-profile-header {
    padding-bottom: 0.5em;
    margin-bottom: 0.4em;
    line-height : 36px;
    font-size   : 24px;

    .header-text {
      font-weight : 700;
      margin-left: 0.25em;
    }
  }

  .card-footer {
    border: none;
    background: white;
    padding: 0 2em 1.5em;
  }

  .customer-number {
    text-align: right;
    margin-top: -15px;
    margin-bottom: 30px;

    @media (max-width: $viewport-xs-max) {
      text-align: left;
      margin-top: 5px;
    }
  }
}
