.zack {
  position: relative;
  top: -75px;
  margin-bottom: -75px;
  // height: 150px;
  // width: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  margin-left: auto;

  @media (min-width: $viewport-lg-min) {
    top: -100px;
    margin-bottom: -100px;
    // height: 200px;
    // width: 200px;
  }
}

.zack-googly {
//   background-image: url('../assets/img/zack/googly.png');
}

.zack-point {
//   background-image: url('../assets/img/zack/point.png');
  z-index: 10;
  top: 0;
  left: 0;
  margin-bottom: -28px;
  margin-right: 30px;

  @media (min-width: $viewport-lg-min) {
    margin-bottom: -36px;
  }
}

.zack-hooray {
//   background-image: url('../assets/img/zack/hooray.png');
  top: 0;
  margin: 0;
  background-position: center center;
}

.zack-spoon {
//   background-image: url('../assets/img/zack/spoon.png');
}

.zack-spoon-half {
//   background-image: url('../assets/img/zack/spoon-half.png');
  top: -45px;
  margin-bottom: -62px;
  height: 150px;
  z-index: 1000;
}

.zack-centered {
  top: 0;
  margin: 0 auto;
  background-position: top center;
}
