@import 'variables';

.online-catering {
  .btn {
    border-radius   : 3px;
    font-family     : $basic-font;
    font-size       : 1rem;
    font-weight     : 600;
    color           : $text-color;
    letter-spacing  : $big-letter-spacing;
    padding         : 9px 12px;
    display         : inline-flex;
    justify-content : center;
    align-items     : center;
  }

  .btn-primary, .btn-success {
    background-color : $primary-color;
    border-color     : $primary-color;
    color            : white;
  }

  .btn-primary:disabled {
    opacity          : 1;
    background-color : $primary-color-light;
    border-color     : $primary-color-light;
  }

  .btn-primary:not(:disabled):not(.disabled):not(.btn-nohover):hover {
    background-color : $primary-color-dark;
    border-color     : $primary-color-dark;
    color            : white;
  }

  .btn-primary:not(:disabled):not(.disabled):focus,
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active {
    background-color : $primary-color-super-dark;
    border-color     : $primary-color-super-dark;
    color            : white;
    box-shadow       : none !important;
  }

  .btn.btn-nohover:focus, .btn.btn-nohover:active {
    background-color: $primary-color;
    box-shadow: none !important;
  }

  .btn-secondary:focus {
    box-shadow : none !important;
  }

  .show > .btn-primary.dropdown-toggle {
    background-color : $primary-color-dark;
    border-color     : $primary-color-dark;
    color            : white;
  }

  .btn-outline-primary {
    border-color : $primary-color;
    color        : $primary-color-dark;
    border-width : 2px;
  }

  .btn-outline-primary:hover,
  .btn-outline-primary:active,
  .btn-outline-primary:focus,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color : $primary-color;
    border-color     : $primary-color;
    color            : white;
  }

  .btn-sm {
    padding: 0.2rem 0.45rem;
  }

  .btn-light {
    background-color : #f1f1f1;
    border-color     : #f1f1f1;

    &:hover, &:active, &:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-color : #e8e8e8;
      border-color     : #e8e8e8;
    }
  }

  .btn-secondary {
    border           : 1px solid $primary-color;
    background-color : white;
    color            : $primary-color;

    &:not(:disabled):not(.disabled):hover {
      border           : 1px solid $primary-color;
      background-color : $primary-color-super-light;
      color            : $primary-color;
    }

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active, {
      background-color : $primary-color-light;
      border           : 1px solid $primary-color;
      color            : $primary-color;
    }

    &:disabled {
      opacity          : 0.5;
      border           : 1px solid $primary-color;
      background-color : white;
      color            : $primary-color;
    }
  }

  .btn-transparent {
    color : $primary-color;

    &:not(:disabled):not(.disabled):hover {
      background-color : $primary-color-super-light;
      color            : $primary-color;
    }

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active, {
      background-color : $primary-color-light;
      color            : $primary-color;
    }

    &:disabled {
      opacity : 0.5;
      color   : $primary-color;
    }
  }

  .table-header-button {
    height     : 37px;
    padding    : 2px 10px !important;
    margin-top : 23px;
  }

  div.dropdown-menu.show {
    background-color : #FFFFFF;
    min-width        : unset;
    padding          : 4px 0 0;
    box-shadow       : 0 4px 26px rgba(204, 204, 204, 0.25);
    border           : none;
  }

  a.dropdown-item {
    color   : #132C43;
    display : inline-flex;
    padding : 9px 12px;
  }

  a.dropdown-item:hover:not(.dropdown-navigation-link) {
    box-shadow       : 0 4px 26px rgba(204, 204, 204, 0.25);
    background-color : #FFFFFF;
  }

  .shadow-card-btn {
    border         : 2px solid $neutral50;
    box-shadow     : 0 4px 26px rgba(204, 204, 204, 0.25);
    border-radius  : 3px;
    cursor         : pointer;
    padding-top    : 15px;
    padding-bottom : 15px;
    font-weight    : bold;
    text-align     : center;

    &.active {
      border-color: $primary-color;
    }
  }

  .btn-link {
    border: none !important;
    color: $primary-color;
    background: transparent;
    box-shadow: none !important;
    font-weight: normal;
    padding: 0;

    &.btn-secondary {
      color: $text-color;
      text-decoration: none;
    }

    &:hover, &:focus, &:active {
      border: none !important;
      color: $primary-color !important;
      background: transparent !important;
    }
  }
}
