@import '../../styles/variables';

.dropdown__control {
  border-radius : 3px !important;
  border-color  : $input-border-color !important;
}

.dropdown__value-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.dropdown__control--is-focused {
  box-shadow: none !important;
}

.dropdown__option--is-focused,
.dropdown__option--is-selected {
  background-color : $primary-color-super-light !important;
  color            : $text-color !important;
}

.dropdown__multi-value {
  border-radius : 0;
}

.dropdown__multi-value__remove {
  border-radius : 0 !important;

  &:hover {
    cursor           : pointer;
    background-color : $primary-color-light !important;
    color            : $primary-color !important;
  }
}

.dropdown__menu {
  z-index : 1010 !important;
}

.dropdown__placeholder {
  color : $gray-text-color !important;
}

.dropdown__single-value {
  color : $text-color !important;
}
