@import "../../styles/variables";

.wallet {
  min-width: 150px;

  .wallet-image {
    width: 100%;
    height: 100%;
    background-size: contain;
  }

  .wallet-text {
    font-weight: 600;
  }

  &.wallet-small .wallet-image {
    height: 50px;
  }

  &.wallet-big {

    .wallet-image {
      height: 80px;
    }

    .wallet-text {
      font-size: 1.5rem;
    }

    .tooltip-text {
      font-size: initial;
    }
  }

  .wallet-onclick-tooltip {
    text-align: left;
    font-size: initial;
    font-weight: initial;
  }

  .wallet-big {

    font-size: 1.5rem;
  }
}

.wallet-tooltip {
  z-index: 2000;
  position: fixed;
}

.page-title-subtitle {
  font-size      : 16px;
  margin-left    : 2.5rem;
  font-weight    : 400;
  vertical-align : middle;
}
