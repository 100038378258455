@import './../../styles/variables';

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color : $primary-color;
}

.nav-pills .nav-link {
  border-radius : 0;
  border        : solid 1px $primary-color;
}

.nav-link.first {
  border-radius : 0.25rem 0 0 0.25rem;
}

.nav-link.last {
  border-radius : 0 0.25rem 0.25rem 0;
}

.nav-link {
  margin-right : 0;
}

.tab {
  .nav-link {
    margin-right : 0
  }
}
