@import '../../styles/variables';

.order-with-limit {

  .order-amount {
    font-weight : bold;
    color       : $text-color;
    font-size   : 0.9rem !important;
    min-height  : 21.6px;
  }

  .icon-tooltip {
    color  : $primary-color;
    cursor : pointer;

    &.disabled {
      color : $primary-color-light;
    }
  }
}