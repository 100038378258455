@import '../../styles/variables';

.tab-label {
  color       : $primary-color;
  font-weight : bold;
}

.day-of-week {
  line-height : 20px;

  .day {
    font-size : 20px;
  }
}

.day-selected {
  font-weight : 800;
}