@import "../../styles/variables";

.week {
  font-size: 1rem;
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;

  &.small .weekday {
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 0.8rem;
  }

  &.is-invalid .weekday {
    border: 2px solid $red;
    color: $red;
  }

  .weekday {
    border-radius: 3px;
    border: 2px solid $primary-color;
    color: $primary-color;
    font-weight: bold;
    margin: 0 4px 4px 0;
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 34px;
    text-align: center;

    &.weekday-selected {
      background: $primary-color;
      color: #fff
    }

    &.weekday-disabled {
      background: none;
      color: $gray-text-color;
      border: 2px solid $gray-text-color;
      cursor: default;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .flexible {
    width: 80px;
  }
}
