@import '../../styles/variables';

.meal.orderable {
  height  : 100%;
  @media (max-width : $viewport-md-max) {
    width : 100%;
  }
  padding : 0.3rem;

  .card {
    -webkit-box-shadow : none;
    -moz-box-shadow    : none;
    box-shadow         : none;
    height             : 100%;
    border-width       : 2px;
  }

  .badge.badge-primary {
    cursor : help;
  }

  &.is-ordered .card {
    border-color : $success;
    border-width : 2px;
  }

  &.order-with-limit .card {
    background-color : $primary-color-light;
  }

  .fa-check-circle {
    font-size : 1.5rem;
    margin    : 0 0 0.5rem 0.5rem;
  }

  .price {
    font-weight : bold;
    font-size   : 1.2rem;
    color       : $text-color;
  }

  .button-footer {
    top        : 1.5rem;
    margin-top : -1rem;
  }
}
