@import '../../styles/variables';

.search {
  background-color  : white;
  border-left-style : none;

  .input-group-text {
    margin-left       : -5px;
    padding-left      : 15px !important;
    background        : white;
    color             : $gray-text-color;
    border-left-style : none;
  }

  .primary-search-icon {
    background-color : $primary-color;
    color            : white;
    border           : 1px solid $primary-color;
    width            : 55px;
  }

}

.form-control:focus {
  color : $text-color;
}

#search {
  border-color       : $input-border-color;
  box-shadow         : none;
  border-right-style : none;
}
