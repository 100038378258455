@import '../../styles/variables';

.total-price {
  font-size   : 1.3rem;
  font-weight : bold;

  .price {
    margin-left : 1rem;
    display     : inline;
  }
}

.card.selected.child-order {
  border-width : 2px;
  border-color : $primary-color;
}
