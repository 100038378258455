@use 'sass:math';
@import '../../styles/variables';
@import '../../styles/basics';

.oc-tooltip {
  $-arrowSize                : 12px;

  z-index                    : 10000;
  display                    : inline-flex;
  align-items                : center;
  filter                     : drop-shadow(0 8px 20px rgba(0, 0, 0, 0.2));
  transition-timing-function : cubic-bezier(0.16, 0, 0.12, 1);
  transition-duration        : 600ms;
  transition-property        : opacity;
  font-size                  : .9rem;
  line-height                : 1.2rem;

  &:before {
    width            : 12px;
    height           : 12px;
    content          : '';
    background-color : white;
    transform        : rotate(45deg);
    border           : 1px solid $neutral50;
  }

  &.tooltip-visible {
    visibility : visible;
    opacity    : 1;
  }

  &.tooltip-hide {
    visibility : hidden;
    opacity    : 0;
  }

  &[data-popper-placement='right'] {
    flex-direction : row;
    margin-left    : $-arrowSize * 0.5;

    .icon-tooltip-body {
      align-items     : center;
      justify-content : center;
      min-height      : 60px;
      margin-left     : math.div($-arrowSize, -2) - 1px;
    }

    &:before {
      border-left   : 0;
      border-bottom : 0;
    }
  }

  &[data-popper-placement='left'] {
    flex-direction : row-reverse;
    margin-right   : $-arrowSize * 0.5;

    .icon-tooltip-body {
      align-items     : center;
      justify-content : center;
      min-height      : 60px;
      margin-right    : math.div($-arrowSize, -2) - 1px;
    }

    &:before {
      border-bottom : 0;
      border-left  : 0;
    }
  }

  &[data-popper-placement='bottom'] {
    flex-direction : column;

    .icon-tooltip-body {
      margin-top : math.div($-arrowSize, -2) - 1px;
    }

    &:before {
      border-bottom : 0;
      border-right  : 0;
    }
  }

  &[data-popper-placement='top'] {
    flex-direction : column-reverse;

    .icon-tooltip-body {
      margin-bottom : math.div($-arrowSize, -2) - 1px;
    }

    &:before {
      border-left : 0;
      border-top  : 0;
    }
  }

  .icon-tooltip-body {
    color            : $text-color;
    background-color : white;
    padding          : 0.5rem 0.75rem;
    @include shadows;
  }
}
