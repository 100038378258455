@import '../../styles/variables';

.info-tooltip {
  font-family : $basic-font;
  font-size   : 0.9rem;
}

.closing-day-circle {
  width                 : 20px;
  height                : 20px;
  padding               : 6px;
  margin-right          : 5px;
  -webkit-border-radius : 25px;
  -moz-border-radius    : 25px;
  border-radius         : 25px;
}

.caterer-closing-day {
  background : $secondary-color;
}

.institution-closing-day {
  background-color : $primary-color;
}
