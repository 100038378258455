@import './../../styles/variables';

.missing-input {
  border-width : 2pt;
  border-color : $red;
}

.welfareModal {
  overflow-y : unset;
}
