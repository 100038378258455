@import 'variables';

h1 {
  margin-top    : 0;
  margin-bottom : 2.5rem;
  font-size     : 2.5rem;
  font-weight   : 700;

  @media (max-width : $viewport-xs-max) {
    font-size : 1.8rem;
  }
}

h2 {
  font-weight : 700;
}

h3 {
  font-weight : 700;
}

h4 {
  font-weight : 700;
}

.text-muted {
  color : $gray-text-color !important;
}

.text-primary {
  color : $primary-color !important;
}

.text-success {
  color : $success !important;
}

.background-success {
  color: #FFFFFF;
  background : $success !important;
}

.text-warning {
  color : $secondary-color !important;
}

.text-error, .text-danger {
  color : $error-color !important;
}

.text-grey {
  color: $neutral70 !important;
}

.card {
  @include shadows;
  border-radius : 3px;

  &.card-hover {
    cursor: pointer;
  }

  &.card-hover:hover {
    box-shadow: 0 4px 26px 0 #CCCCCCC0;
    transform: scale(1.01)
  }

  .card-header {
    background    : none;
    border        : none;
    color         : $text-color;
    border-radius : 0;
    font-family   : $header-font;
    font-size     : 1.5rem !important;
    font-weight   : 700;

    @media (min-width : $viewport-lg-min) {
      border-radius : 20px;
    }
  }

  @media (min-width : $viewport-xl-min) {
    .card-header {
      padding-top   : 1.5rem;
      padding-left  : 2rem;
      padding-right : 2rem;
      font-size     : 1.2rem;
    }

    &.slidedown-card {
      .card-header.slidedown-card-closed {
        padding-top: 1rem;
      }
    }

    .card-body {
      padding-top : 1.5rem;
      padding-bottom : 1.5rem;
      padding-left  : 2rem;
      padding-right : 2rem;
    }

    .card-header + .card-body,
    .card-header + .react-slidedown .card-body {
      padding-top : 0;
    }
  }

  @media (max-width : $viewport-md-max) {
    .card-body {
      padding : 1rem;
    }
  }

  h3 {
    color       : $text-color;
    font-family : $header-font;
    font-size   : 1.5rem !important;
    font-weight : 700;
    margin      : 2.5rem 0 1rem 0;
  }

  .button-footer {
    position   : relative;
    top        : 2.5rem;
    margin-top : -2rem;
    text-align : right;
    z-index    : 1000;
  }

  .card-footer {
    border-radius : 0;
    background    : #faf9f76e;
    border-color  : #ecebe8 !important;
    margin-top    : 0 !important;
  }
}

.form-control {
  color         : $text-color;
  border-radius : 0;
  padding       : 4px 1rem;
  border-color  : $input-border-color;

  &::placeholder {
    color : $gray-text-color;
  }
}

.input-group-text {
  border-radius : 0;
  background    : $primary-color-super-light;
  color         : $primary-color-dark
}

.nav-tabs, .nav-tabs .nav-link {
  border        : none;
  color         : $gray-text-color;
  font-weight   : 600;
  padding-top   : 0;
  padding-left  : 0;
  padding-right : 0;

  &:hover {
    color : $primary-color;
  }

  @media (min-width : $viewport-md-min) {
    font-size : 1.2rem;
  }
}

.nav-tabs .nav-item {
  margin-left  : 1rem;
  margin-right : 1rem;

  &:first-child {
    margin-left : 0;
  }

  &:last-child {
    margin-right : 0;
  }
}

.nav-tabs .nav-link.active {
  color         : $primary-color;
  border-bottom : 3px solid $primary-color;
}

.form-group label {
  font-size     : 90%;
  color         : $text-color;
  margin-bottom : 0;
}

.form-group .react-datepicker-wrapper {
  width : 100%;
}

.progress {
  border        : 1px solid $primary-color;
  border-radius : 7px;
  background    : transparent;
  height        : 0.95rem;
  padding       : 2px;
}

.progress-bar {
  border-radius    : 7px;
  background-color : $primary-color;
}

.bg-primary,
.card.bg-primary .card-header {
  background-color : $primary-color-light !important;
}

.row.inline-row {
  margin-left  : 0;
  margin-right : 0;
}

.border-primary {
  border-color : $primary-color !important;
}

.border-success {
  border-color: $success !important;
}

.badge-light {
  background-color : rgba(0, 0, 0, 0.05);
}

.badge.badge-primary {
  background-color : $primary-color;
}

.popover {
  @include shadows;
  font-family   : $basic-font;
  font-size     : 0.9rem;
  border-radius : 0;
}

.popover-header {
  background  : transparent;
  font-family : $subheader-font;
}
