@import './../../styles/variables';

.file-picker-drop-zone {

  &.dragover{
    border-color: $primary-color;
  }

  border: 2px dashed $neutral70;
  padding: 1em
}
