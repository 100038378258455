@import '../../styles/variables';

.account-type {
  height              : 250px;
  max-width           : 100%;
  background-size     : contain;
  background-position : center center;
  background-repeat   : no-repeat;

  &.account-type-parent {
//     background-image : url('../../assets/img/parents.png');
  }
}

.account-type-small {
  height              : 100px;
  background-position : center center;
  margin-top          : -2rem;
  margin-bottom       : 1.5rem;

  @media (min-width : $viewport-md-min) {
    margin-top          : -6.5rem;
    margin-bottom       : 0;
    background-position : top right;
  }
  @media (min-width : $viewport-lg-min) {
    height     : 170px;
    margin-top : -8.5rem;
  }
}

.login-link {
  a {
    color           : $red;
    text-decoration : underline $red;
  }
}
