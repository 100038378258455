@import '../../styles/variables';

#icon-nav-link {
  margin-top : 6px;

  &:hover {
    text-decoration : none;
  }

  &.active {
    .nav-link {
      color : $primary-color;
    }

    .nav-text {
      color               : $primary-color;
      letter-spacing      : $big-letter-spacing;
      border-bottom       : $primary-color;
      border-bottom-style : solid;
      border-width        : 2px;
      padding-bottom      : 6px;
    }
  }

  .nav-link {
    color          : $text-color;
    letter-spacing : $big-letter-spacing;
  }

  .icon-link {
    margin : 0 8px 0 0;
  }
}

.nav-link {
  @media (min-width : 1200px) and (max-width : 1260px) {
    margin : 0 2px 0 0;
  }
  margin      : 0 12px 0 0;
  font-weight : 600;
  font-size   : 16px;
  line-height : 24px;
}

