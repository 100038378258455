@import "../../styles/variables";

.slick-prev:before {
  color: $text-color;
}

.slick-next:before {
  color: $text-color;
}

.slick-disabled {
  visibility: hidden;
}

#meal-slider {
  display: flex;
  flex-wrap: nowrap;

  .arrow-left {
    text-align: right;
    flex: 0 0 2%;
    max-width: 2%;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .carousel-list {
    flex: 0 0 96%;
    max-width: 96%;
  }

  .arrow-right {
    flex: 0 0 2%;
    max-width: 2%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 2px;
    cursor: pointer;
  }
}
