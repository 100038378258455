@import './../../styles/variables';

.food-restriction {
  border : 2px solid $primary-color-super-light;

  &.selected {
    border-color : $primary-color;
  }

  &.selected:focus,
  &.selected:active,
  &.selected:hover {
    cursor           : pointer;
    background-color : $primary-color-super-light;
  }

  &.selected.pre-upload:focus,
  &.selected.pre-upload:active,
  &.selected.pre-upload:hover {
    cursor           : default;
    background-color : transparent;
  }

  &.invalid {
    border-color : $red;
  }

  .btn {
    height    : 104px;
    width     : 104px;
    font-size : medium;

    img {
      display    : block;
      max-height : 56px;
      max-width  : 64px;
      margin     : 8px auto 4px;
    }
  }
}

.spelling {
  hyphens : auto;
}
