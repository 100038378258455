@import "../../styles/variables";

.children-overview {
  @media (min-width: $viewport-xl-min) {
    .card {
      .card-header, .card-body {
        padding: 1.5rem;
      }
    }
  }

  .order-button {
    min-width: 120px;
  }
}
