@import '../../styles/variables';

.order-type-switch {
  .card {
    cursor  : pointer;
    padding : 1px;

    .card-header {
      line-height : 2.5rem;
    }

    &.selected, &:hover {
      border-width : 2px;
      border-color : $primary-color;
      padding      : 0;

      .card-header {
        color : $primary-color;

        .fa-check-circle {
          font-size : 2rem;
        }
      }
    }
  }
}
