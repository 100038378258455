@import '../../styles/variables';

.finance {
  .fix-text-group {
    label {
      font-size     : 90%;
      font-weight   : bold;
      margin-bottom : 0;
    }

    p {
      white-space   : pre-wrap;
      margin-bottom : 0
    }
  }

  .switch {
    margin-bottom : 3rem;

    @media (min-width : $viewport-md-min) {
      .btn {
        padding-left  : 3rem;
        padding-right : 3rem;
      }
    }
  }

  .filter {
    .btn {
      color : $primary-color;
    }

    .btn-focused {
      color : green;
    }

    .btn:active,
    .btn:hover,
    .btn:focus {
      color      : green;
      box-shadow : none;
    }
  }

  .card-title-status {
    font-family : $basic-font;
    font-size   : 1rem;
    font-weight : normal;
    line-height : 80%;
  }

  .center {
    @media (min-width : $viewport-xs-min) and (max-width : $viewport-sm-max) {
      padding : 3rem 0;
    }

    @media (min-width : $viewport-xl-min) {
      margin : 1rem 5rem;
    }

  }

  .shadow-card {
    &:hover {
      background-color : $primary-color-super-light;

      .card-title {
        color : $primary-color;
      }
    }
  }
}

.notification {
  text-align: left;
}

.no-scrollbar::-webkit-scrollbar {
  display : none;
}

.no-scrollbar {
  -ms-overflow-style : none; /* IE and Edge */
  scrollbar-width    : none; /* Firefox */
}

.information {
  box-shadow : none;
  border     : none;

  td {
    span {
      padding : 0.75rem;
      display : grid;
    }
  }
}

.form-group {
  label {
    font-size : initial;
  }

  .permission_info {
    font-size   : 0.9rem;
    line-height : 1.2rem;
  }
}

.back-link:hover {
  color  : $primary-color-dark !important;
  cursor : pointer;

  span:hover {
    text-decoration : underline;
  }
}
