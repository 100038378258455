@import '../../styles/variables';

.online-catering {
  .card .card-header.deleted {
    color: $gray-text-color;
    text-decoration: line-through;
  }

  .card.child-detail-card .d-table-row {
    line-height: 2;
  }

  .child-summary-grid {
    .child-summary-grid-item {
      display: flex;
      height: 35px;

      @media (max-width : $viewport-xs-max) {
        display: block;
        height: unset;
        margin-bottom: 0.75em;
      }

      .grid-item-first-col {
        width: 200px;
        min-width: 200px;
        padding-right: 1em;

        @media (max-width : $viewport-xs-max) {
          margin-bottom: 0.3em;
        }
      }
    }
  }
}
