@import '../../styles/variables';

.meal {
  .meal-date {
    font-size   : 0.9rem;
    font-weight : bold;
    color       : $gray-text-color;
    text-align  : center;
  }

  .card {
    min-height         : 200px;
    -webkit-box-shadow : 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow    : 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow         : 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    border             : 1px solid rgba(0, 0, 0, 0.1);

    .card-body, .badge {
      font-size : 0.8rem;
    }

    .card-header {
      font-weight : bold;
      color       : $text-color;
      font-family : $basic-font;
      font-size   : 0.9rem !important;

      .is-ordered {
        font-size : 2rem;
        margin    : 0 0 0.5rem 0.5rem;
      }
    }

    .card-header, .card-body, .card-footer {
      padding : 1rem 0.7rem !important;
    }

    .card-header + .card-body,
    .card-header + .react-slidedown .card-body {
      padding-top : 0 !important;
    }

    .card-footer {
      background  : transparent;
      border      : none;
      padding-top : 0 !important;

      .no-change-possible {
        line-height : 1.5;
        padding     : 0.15rem 0.4rem;
        text-align  : center;
      }
    }

    .info {
      font-size : 1.2rem;
    }
  }

  /**
 * Positions
 */
  .position-top-right {
    top   : 5px;
    right : 0;
  }

}
