@import '../../styles/variables';


@media (max-width : $viewport-sm-max) {
  .table-container .table tbody td {
    vertical-align : baseline
  }
  .table th, .table td {
    padding : 0;
  }
}
