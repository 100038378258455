#dummy {
  .container {
    position: relative;
    width: 90vw;
    height: 90vh;
    margin: auto;

    h1 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 50vw;
      height: 10vh;
    }
  }
}