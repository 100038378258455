@import '../../styles/variables';

$box-size     : 24px;
$margin-right : 10px;

.form-checkbox {
  position      : relative;
  left          : $box-size + $margin-right;
  padding-right : $box-size + $margin-right;

  input[type='checkbox'] + label {
    line-height : $box-size;
    cursor      : pointer;
  }

  input[type='checkbox'] + label:before {
    border           : 1px solid $input-border-color;
    content          : '\00a0';
    background-color : white;
    display          : inline-block;
    font             : 16px/1em $basic-font;
    vertical-align   : top;
    height           : $box-size;
    width            : $box-size;
    padding          : 5px;
    position         : relative;
    left             : -($box-size + $margin-right);
    margin-right     : -($box-size);
  }

  input[type='checkbox'].is-invalid + label:before {
    border-color : $error-color;
  }

  input[type='checkbox'].read-only + label:before {
    color            : #505050 !important;
    background-color : #e9ecef !important;
    border-color     : #e9ecef !important;
  }

  input[type='checkbox']:checked + label:before {
    font-family  : $basic-font;
    background   : $primary-color;
    color        : #fff;
    content      : '\2713';
    border-color : $primary-color;
    text-align   : center;
  }

  input[type='checkbox']:checked + label:after {
    font-weight : bold;
  }

  input[type='checkbox']:indeterminate + label:before {
    font-family  : $basic-font;
    background   : $primary-color;
    color        : #fff;
    content      : '\2014';
    font-size    : 22px;
    font-weight  : bold;
    line-height  : 12px;
    border-color : $primary-color;
    text-align   : center;
  }
}

.is-nullable-false:before {
  background : red !important;
  border     : red !important;
  content    : '\0058' !important;
}

.checkbox-option {
  width : 90%;
}
