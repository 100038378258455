@import '../../styles/variables';

.withdraw-amount-card {
  border        : 1px solid $neutral50;
  box-shadow    : 0 4px 26px rgba(204, 204, 204, 0.25);
  border-radius : 3px;
  padding       : 17px 24px;

  .bold-primary-text {
    color       : $primary-color;
    font-weight : bold;
  }

  .change-button {
    cursor : pointer;
  }
}

.grey-line {
  height     : 30px;
  background : $neutral-color;
  margin-top : 32px;
}

.payment-item {
  border        : 1px solid $neutral50;
  box-shadow    : 0 4px 26px rgba(204, 204, 204, 0.25);
  border-radius : 20px;
  height        : 180px;
  cursor        : pointer;

  &.selected {
    border-color : $success;
  }

  &:hover {
    box-shadow    : 0 6px 26px rgba(141, 141, 141, 0.25);
    border-radius : 20px;
  }

  .selected-icon {
    display          : inline-block;
    border-radius    : 60px;
    background-color : $success;
    color            : white;
    position         : absolute;
    right            : 40px;
    padding          : 4px;
    top              : 24px;
  }
}

.payment-type {
  font-weight    : 600;
  font-size      : 20px;
  line-height    : 30px;
  letter-spacing : 0.02em;
  color          : $text-color;
  position       : absolute;
  bottom         : 56px;
  left           : 34px;
}

.payment-details {
  font-weight    : 400;
  font-size      : 16px;
  line-height    : 24px;
  letter-spacing : 0.02em;
  color          : $neutral50;
  position       : absolute;
  bottom         : 34px;
  left           : 34px;

  .payment-details-text {
    color : $text-color;
  }
}

.error-text {
  color : red;
}
