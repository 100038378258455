@import '../../styles/variables';

.description-check-icon {
  color        : $success;
  margin-right : 12px;
}

.description-info-icon {
  color        : $neutral-blue;
  margin-right : 12px;
}

.bold-primary-text {
  font-weight    : 600;
  font-size      : 16px;
  line-height    : 24px;
  letter-spacing : 0.04em;
  color          : $primary-color;
}

.change-button {
  cursor : pointer;
}

.payment-selection-card {
  width: 340px;
  margin-bottom: 1.5rem;
}
