@import '../../styles/variables';

.switch {
  border-radius : 7px;
  padding       : 7px;
  display       : inline-block;

  a {
    color          : $text-color;
    letter-spacing : $big-letter-spacing;
  }

  .switch-item {
    padding-right : 12px;
    margin        : 7px 20px 7px 0;
    cursor        : pointer;
    font-weight   : 600;

    &:hover {
      text-decoration : none;
    }

    .icon-tooltip {
      padding-right : 5px;
    }

    .active {
      color               : $primary-color;
      border-bottom       : $primary-color;
      border-bottom-style : solid;
      border-width        : 2px;
      padding-bottom      : 6px;
    }
  }
}
