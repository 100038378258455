@import '../../styles/variables';
@import '../../styles/basics';

.table-container {
  @include shadows;
  padding          : 0 15px 15px 15px;
  margin-top       : 15px;
  background-color : white;
  overflow-x       : auto;
  width            : 100%;

  &.withinTab {
    border     : none;
    box-shadow : none;
  }

  .table {
    margin-bottom : 0;

    .sort-header {
      cursor : pointer;
    }

    .sort-header:hover {
      background-color : #FBFBFA;
    }

    thead.thead-light {
      th {
        color            : $text-color;
        background-color : white;
        font-weight      : bold;
        text-align       : left;
        vertical-align   : top;
        border-top       : 2px solid transparent;

        &.col-sort-desc {
          border-bottom : 2px solid $primary-color
        }

        &.col-sort-asc {
          border-top : 2px solid $primary-color;
        }
      }
    }

    tr {
      &.inactive-row {
        td {
          color : $gray-text-color;
        }
      }

      td {
        color          : $text-color;
        vertical-align : middle;
      }
    }

    tbody td {
      background : inherit;
    }

    th {
      font-weight  : 500;
      border-color : #ECEBE8;
    }
  }

  .filter-text-box {

    .form-group {
      margin-bottom : 0;
    }
  }

  .pagination {
    justify-content : center;
    padding         : 1em 0;
    margin-bottom   : 1em;
    font-size       : 0.9em;
    color           : gray;
    border-top      : 1px solid gray;
    border-radius   : 0;
  }

  .responsive-table {
    overflow-y                 : auto;
    -webkit-overflow-scrolling : touch
  }

  .can-row-selected:hover {
    cursor : pointer;
    @include shadows;
  }

  &.recipeEditTable {
    overflow-x : visible;
  }
}


.table-header-tooltip::after {
  content      : ' ';
  position     : absolute;
  top          : 100%; /* At the bottom of the tooltip */
  left         : 50%;
  margin-left  : -5px;
  border-width : 5px;
  border-style : solid;
  border-color : black transparent transparent transparent;
}

.table-header-tooltip {
  @include shadows;
  padding    : 5px 12px 4px;
  background : #132C43;
  color      : white
}
