@import './../../styles/variables';

.shadow-card {
  border        : 1px solid $neutral50;
  box-shadow    : 0 4px 26px rgba(204, 204, 204, 0.25);
  border-radius : 3px;
  padding       : 24px 36px;
  margin-right  : 24px;

  .card-title {
    font-weight    : 600;
    font-size      : 20px;
    text-align     : center;
    letter-spacing : 0.02em;
    color          : $text-color;
  }

  &.card-btn {
    margin-bottom : 24px;
    margin-right  : 0;
    cursor        : pointer;
    padding       : 0;
    max-height    : 72px;


    .card-title {
      white-space: nowrap;
      overflow : hidden;
      text-overflow: ellipsis;
    }

    .card-title, .card-info {
      font-size     : 16px;
      text-align    : left;
      margin-bottom : 0;
    }

    @media (min-width : $viewport-xs-min) and (max-width : $viewport-md-max) {
      .card-title {
        padding : 16px;
      }

      .card-info {
        padding : 16px 16px 16px 0;
      }
    }

    @media (min-width : $viewport-lg-min) {
      .card-title {
        padding : 24px;
      }

      .card-info {
        padding : 24px 24px 24px 0;
      }
    }

    .card-info-text {
      padding-right : 8px;
    }
  }

  .cursor-point, &.cursor-point {
    cursor : pointer;
  }

  &.card-small {
    padding      : 16px;
    margin-right : 0;
  }

  &.active {
    border-color : $primary-color;
    border-width : 2px;
  }

  &:hover {
    box-shadow       : 0 6px 26px rgba(141, 141, 141, 0.25);
  }
}
